import React from 'react'
import Layout from '../components/layout'
import RegForm from '../components/RegForm'

function Registration () {

  return (
    <Layout>
      <RegForm/>
    </Layout>
  )
}

export default Registration